.slider {

    position: relative;
    top: 3rem;

    margin-inline: auto;

    width: 50rem;
    height: max-content;

    border: 0;


    .ArrowSlider {
        position: absolute;
        color: #000000;
        font-size: 2.5em;
        font-weight: bold;

        width: 3rem;
      
        cursor: pointer;
      }
      
      .nextArrow {
        top: 14rem;
        right: -6rem;
        transform: rotate(180deg);

      }
      
      .prevArrow {
        top: 14rem;
        left: -6rem;
      }

    .card {
        height: 30rem;
        width: 100%;
        background-color: transparent;
        border: 0;
    }

    .card:focus {
      outline: 0;
    outline: none;
    }

    .multi-card {
      .ArrowSlider {
        display: none;
      }

      .card {
        height: 10rem;
      }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    margin-bottom: 10rem;
}