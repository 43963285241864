.slider {

    @media only screen and (max-width: 1050px) {
        width: 90%;

        top: 0rem;


        .ArrowSlider {
            display: none;
        }

        .card {
            width: 100%;
            height: 30rem;
        }

        .multi-card {
            .card {
                height: 12%;
            }
        }

        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 600px) {
        width: 90%;

        top: 0rem;


        .ArrowSlider {
            display: none;
        }

        .card {
            width: 100%;
            height: 20rem;
        }

        .multi-card {
            .card {
                height: 10%;
            }
        }

        margin-bottom: 2rem;
    }

}