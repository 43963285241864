.Contatti {
    .Content {

        overflow: hidden;



        .map {
            width: 80%;
            height: 20rem;
            border:0;

            position: relative;
            margin-top: 4rem;
            margin-bottom: 4rem;
            
            left: 10%;
        
        }

        form {

            position: relative;
            margin-top:5%;
            text-align: center;


            input {
                width: 70%;
                margin-top: 1rem;
                padding: 20px;
                border: 0;
                background-color: rgb(224, 224, 224);
                color: black;
            }

            textarea {
                width: 70%;
                margin-top: 1rem;
                padding: 20px;
                border: 0;
                background-color: rgb(224, 224, 224);
                color: black;
            }

            button {
                margin-top: 1rem;
                padding: 20px;
                padding-inline:50px;
                border:0;
                left:0;

                background-color: rgb(140, 187, 231);
                font-size: 1em;
            }   

        }
    }
}