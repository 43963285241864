.Referenze {

    overflow: hidden;

    .Contents {
        margin-inline: auto;
        position: relative;

        h1 {
            position: relative;
            margin-top: 4rem;
            margin-bottom: 4rem;
            text-align: center;

            color: rgb(48, 48, 48);
        }
    }


    .container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        div {
            text-align: center;
            flex: 25%;
            padding-bottom: 60px;
            

            p { 
                position: relative;
                width: 10rem;
                text-align: center;
                margin-top: 20px;
                margin-inline: auto;
                color: hsl(0, 0%, 34%);

            }


        }
    }


}