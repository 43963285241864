.Navbar {

    @media only screen and (max-width: 1100px) {
        .StaticMenu {
            display: none;
        }
    }


    @media only screen and (min-width: 1101px) {
        .DynamicMenu {
            display: none;
        }
    }

}