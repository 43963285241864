.Footer {

    @media only screen and (max-width: 1100px) {
        height: 70rem;

        div {
            flex: 0 0 90%;
        }
    }

    @media only screen and (max-width: 400px) {
        div {
            flex: 0 0 90%;
        }
    }
}