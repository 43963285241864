.Navbar {
    position: sticky;
    width: 100%;
    top: 0;
    
    background-color: white;
    height: 10vh;

    z-index: 3;

    border-bottom: 1px solid #e8ebed;

    a {
        color: black;
        text-decoration: none;
    }

    .Logo {
        position: absolute;

        top: 50%;
        left: 4rem;
        
        transform: translate(-50%, -50%);
        
        img {
            position: relative;
            width: 11vh;
            height: 8vh;
        }

    }

    .StaticMenu {
        position: absolute;
        top: 50%;
        right: 2rem;

        transform: translate(0%, -50%);

        ul, li {
            list-style: none;
            display: inline;

            padding: 10px 10px 5px 10px;

            font-size: 0.93em;
            font-weight: 500;

        }

        li:hover {
            cursor: pointer;
            color: hsl(214, 62%, 54%);
            border-bottom: 2px solid hsl(214, 62%, 54%);
        }
    }


    .DynamicMenu {
        .ButtonMenu {
            position: absolute;

            top: 50%;
            right: 0.6rem;
            transform: translate(0%, -50%);

            width: 3.5rem;
            height: 3.5rem;

            border: 2px solid black;

            @keyframes Close {
                0% { opacity: 0}
                100% {  opacity: 1}
            }

            .Line {
                position: relative;

                top: 0.3rem;
                left: 50%;
                transform: translate(-50%, -50%);

                width: 2.3rem;
                height: 3px;

                margin-top: 0.6rem;

                animation: Close ease-in 0.4s;

                background-color: black;

            }

            @keyframes Open1 {
                0% { transform: rotateZ(0) }
                100% { transform: rotateZ(45deg) }
            }

            .OpenLine1 {
                position: absolute;
                top: 50%;
                left: 14%;
                margin-top: 0;
                transform: translate(-50%, -50%);

                transform: rotateZ(45deg);
                animation: Open1 ease-in 0.4s;
            }

            @keyframes Open2 {
                0% { transform: rotateZ(0) }
                100% { transform: rotateZ(-45deg) }
            }

            .OpenLine2 {
                position: absolute;
                top: 50%;
                left: 14%;
                margin-top: 0;

                transform: translate(-50%, -50%);

                transform: rotateZ(-45deg);
                animation: Open2 ease-in 0.4s;
            }

            @keyframes Open3 {
                0% { opacity: 1 }
                100% { opacity: 0 }
            }

            .OpenLine3 {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: 0;

                transform: translate(-50%, -50%);
                opacity: 0;
                animation: Open3 ease 0.4;
            }

        }

        .Menu {

            position: absolute;
            right: 0;

            margin-top: 10vh;

            li {
                cursor: pointer;

                display: none;
                padding: 24px;
                text-align: center;
                list-style: none;
                font-size: 16px;
                border-bottom: 1px solid #e8ebed;
                border-left: 1px solid #e8ebed;

                background-color: white;
                color: hsl(214, 62%, 54%);
                
            }


            @keyframes TranslateX {
                0% {transform: translateX(20rem); opacity: 0;}
                100% {transform: translateX(0rem); opacity: 1;}
            }

            @for $num from 1 through 8 { 
                .dropdown_item-#{$num} {
                    display: block;
                    animation: TranslateX 400ms ease-in-out;
                    
                }
            }
        }
    }
}