.Chisiamo {
    .Content {

        overflow: hidden;

        margin-inline: auto;

        h1 {
            margin-top: 4rem;
            margin-left: 10%;
            color: rgb(48, 48, 48);
        }

        p { 
            position: relative;
            width: 80%;

            text-align: left;

            color: hsl(0, 0%, 34%);
            letter-spacing: 1.5px;
            font-size: 0.95em;
            
        }

        .first {
            margin-top: 5rem;
            margin-inline: auto;
        }

        .Container {
            width: 100%;

            display: flex;
            
            margin-top: 5rem;

            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            gap: 4%;

            span {
               display: inline-flex;
               flex: 0 0 38%;
            }

            img {
                position: relative;
                width: 90%;
            }

            .second {
                text-align: left;
                width: 100%;
            }

            margin-bottom: 4rem;
        }
    }

    .Certificazioni {
        display: flex;
        width: 100%;
        height: 80vh;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        margin-bottom: 20rem;
        margin-top: 1rem;
        gap: 4%;


        .description {
            flex: 0 0 40%;


            .title {
                background-color: rgb(255, 207, 48);
                width: 90%;
                text-align: center;
                padding: 30px;
                font-size: 1em;
                letter-spacing: 1.5px;
            }


            ul {
                list-style: none;
                li {
                    padding: 10px;
                    color: hsl(0, 0%, 34%);
                    font-size: 0.95em;
                    letter-spacing: 1.5px;
                }

            }

        }

    }
}   