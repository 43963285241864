.Tecnologie {
    .Contents {
        overflow: hidden;

        position: relative;
        width: 100%;
        height: 90vh;

        @media only screen and (min-width: 1101px) {
            @keyframes open-fold {
                0% {transform: scaleX(0) rotate(4deg); }
                100% {transform: scaleX(1) rotate(4deg); }
            }
        }

        @media only screen and (max-width: 1100px) {
            @keyframes open-fold {
                0% {transform: translateX(-40rem); opacity: 0; }
                100% {transform: translateX(0rem); opacity: 1; }
            }
        }

        @for $i from 0 through 4 {
            .Card-#{$i} {
                display: none;
                cursor: pointer;
    
                position: absolute;
                width: 21.2%;
                height: 140%;
    
                top: -5rem;
                left: -4% + 21.2% * $i;
            
                transform: rotate(4deg);

                animation: open-fold 1.2s ease-out;
                transform-origin: 0% 100%;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    transition: all 0.6s ease-in-out;
                }

                h1 {
                    position: absolute;
                    z-index: 2;
                    top: 20%;
                    left: 50%;
                    color: white;
                    font-size: 1.9vw;
                    transform: rotate(-4deg) translate(-50%, -50%);
                    -webkit-text-stroke: 0.4px black;
                    transition: all 0.6s ease-in-out;
                    letter-spacing: 1px;
                }

                &:hover {
                    overflow: hidden;

                    img {
                        width: 150%;
                        filter: brightness(60%);

                    }

                    h1 {
                        font-size: 1.8em;
                    }

                }
    
            }
        }

    }
}