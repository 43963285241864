.Home {
     height: max-content;

}


.arrow {
    @keyframes arrow_animation {
        0% { transform: translateY(0)}
        50% { transform: translateY(10vh); opacity: 0;}
        100% {transform: translateY(0); opacity: 1;}
    }

    img {
        width: 9vh;
        height: 9vh;

        position: absolute;
        bottom: 0;

        top: 70%;
        left: 46%;
        transform: translate(-50%, -50%);
        
        animation: arrow_animation 2.3s infinite;
        
    }
}

.Presentation {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 70vh;

    background-image: url('../../images/Home/Findmore/SILVANO.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;

}

.Lavori {
    position:relative;

    height: 40rem;

    @keyframes show_up {
        0% {transform: translateY(10rem); opacity: 0;}
        100% {transform: translateY(0); opacity: 1;}
    }

    .active {
        display: inline-flex;
        animation: show_up 1.5s ease-in-out;
    }

    span {
        position: relative;

        display:hidden;
        width: 25%;
        height: 20rem;
        margin-top: -0.3rem;

        overflow: hidden;

        img {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;

            object-fit: cover;

            transition: all 0.6s ease-out;
        }

        @keyframes show_down {
            0% {transform: translate(-50%, -300%); opacity: 0;}
            100% {transform: translateY(-50%, -50%); opacity: 1;}
        }

        .textactive {
            animation: show_down 2s ease-in-out;
            display: block;
        }

        h1 {

            position: absolute;
            display: none;
            text-align: center;
            
            left: 50%;
            top: 83%;

            transform: translate(-50%, -50%);

            color: white;

            font-weight: 800;
            font-size: 1.7em;
            letter-spacing: 0.03em;

            -webkit-text-stroke: 0.2px black;

            
            transition: all 0.6s ease-in-out;
        }


        &:hover {
            h1 {
                top: 45%;
                font-size: 2.3em;

            }

            img {
                transform: scale(110%);
                filter: brightness(60%);
            }

        
        }

    }

}

@keyframes opacity_up {
    0% { opacity: 0;}
    100% {opacity: 1;}
}

.active-opacity {
    animation: opacity_up 2s ease-in-out;
}

.Whoami { 
    
    a {
        text-decoration: none;
        color: black;
    }
    
    position: relative;

    margin-top: -1rem;

    height: max-content;

    @keyframes show_up {
        0% {transform: translateY(5rem); opacity: 0;}
        100% {transform: translateY(0); opacity: 1;}
    }

    .active {
        display: block;
        animation: show_up 1.5s ease-in-out;

        @keyframes scale-text {
            0% {transform: scaleY(0%);}
            100% {transform: scaleY(100%);}
        }


        .Title, .Findmore, .Description {
            animation: scale-text 2s ease-in-out;
        }

    }

    img {
        display: hidden;
        position: relative;
        width: 100%;
        height: 20rem;

        object-fit: cover;
    }

    .Container {

        position: relative;

        display: inline-flex;
        flex-wrap: wrap;

        width: 100%;

        align-items: center;
        justify-content: center; 
        
        gap: 6rem;
        margin-top: 3rem;


        div {
            position: relative;

            height: 24rem;
            width: 30rem;

            flex: 0 0 30%;
            border-left: 1px solid black;

            img {
                cursor: pointer;
                width: 100%;
                height: 12rem;
            }

            .Title {
                margin-top: 2rem;
                margin-left: 1rem;

                font-size: 1.5em;
                font-weight: 300;
            }
            .Description {
                width: 20rem;

                margin-left: 1rem;
                margin-top: 1rem;

                font-size: 1em;
                font-weight: 300;
            }

            .Findmore {
                cursor: pointer;
                position: absolute;
                bottom: 1rem;
                margin-left: 1rem;

                .arrow {
                    position: absolute;
                    top: -0.87rem;
                    margin-left: 7.2rem;
                    font-size: 2.2em;
                }
            }

        }
    }

}

.Certificati {
    position: relative;
    margin-top: 10rem;
    margin-bottom: 10rem;

    h1 {
        letter-spacing: 8px;
        font-size: 2em;
        color: hsl(0, 0%, 31%);
        text-align: center;
    }

    .Container {

        position: relative;

        display: inline-flex;
        flex-wrap: wrap;

        width: 100%;

        align-items: center;
        justify-content: center; 
        
        gap: 6rem;
        margin-top: 5rem;


        div {

            flex: 0 0 30%;
            img {
                width: 100%;
                box-shadow: 2px 4px 6px 0px gray;
            }

            p {
                text-align: center;
                margin-top: 1.5rem;
                font-size: 1.1em;    
            }
        }
    }
}