.Content {

    margin-inline: auto;

    h1 {
        margin-top: 4rem;
        margin-left: 10%;
        color: rgb(48, 48, 48);
    }

    p { 
        position: relative;
        width: 80%;

        text-align: left;

        color: hsl(0, 0%, 34%);
        letter-spacing: 1.5px;
        font-size: 0.95em;

        margin-inline: auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
        
    }
}