.Home {

    @media only screen and (max-width: 1100px) {
        .Lavori {

            height: 80rem;

                span {
                    width: 50%;
                    height: 20rem;
                    object-fit: cover;

                h1 {
                    top: 45%;
                    font-size: 2em;
                }
            }
        }

        .Whoami {
            .Container {

                div {
                    flex: 0 0 45%;
                    
                }

                div:nth-child(3) {
                    display: none;
                }
            }
        }

        .Certificati {
            .Container {

                div {
                    flex: 0 0 60%;
                    
                }

            }
        }


    }

    @media only screen and (max-width: 670px) {
        .Lavori {

            height: 120rem;

                span {
                    width: 100%;
                    height: 15rem;
                    object-fit: cover;

                
                h1 {
                    top: 45%;
                    font-size: 2em;
                }
            }
        }

        .Whoami {

            img {
                height: 15rem;
            }

            .Container {
                div {
                    margin-bottom: 2rem;
                    flex: 0 0 90%;
                    width: 20rem;
                }

                div:nth-child(3) {
                    display: block;
                }
            
            }

            .Certificati {
                .Container {
    
                    div {
                        flex: 0 0 80%;
                        
                    }
                    
                }
            }
        }

    }
}