.Tecnologie {


    @media only screen and (max-width: 1350px) {
        .Contents {
            height: 79vh;
        }
    }


    @media only screen and (max-width: 1100px) {
        .Contents {
            position: relative;

            display: flex;

            flex-direction: column;
            width: 100%;
            height: 50rem;


            gap:0rem;

            .Card {
                position: relative;
                transform: rotate(0deg);
                height: 10rem;
                width: 100%;

                left:0%;
                top: 0;
                margin-top: -3px;

                

                h1 {
                    top:50%;
                    left:50%;
                    margin-inline: auto;
                    transform: rotate(0deg) translate(-50%, -100%);
                    font-size: 1.5em;

                }

                img {
                    width:100%;
                    height: 100%;

                    position: relative;
                    object-fit: cover;
                }
            }
        
        }
    }
}