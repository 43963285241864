.Chisiamo {
    @media only screen and (max-width: 1300px) {
        .Content {
            .Container {
                
                span {
                    flex: 0 0 80%;
                }

                img {
                    margin-top: 5rem;
                    width: 100%;
                }
            }
        }

        .Certificazioni {
            .description {
                flex: 0 0 100%;
            }
        }
    }
}