.Footer {
    overflow: hidden;
    width: 100%;
    height: 25rem;

    position: relative;
    bottom: 0;

    background-color: hsl(0, 0%, 27%);

    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;


    gap: 5rem;

    div {
        flex: 0 0 25%;
        margin-top: 3rem;
    }

    div {
        position: relative;        

        text-align:left;

        color: white;

        h1 {

            font-size: 1.4em;
            letter-spacing: 1px;
        }

        ul, li {

            a {
                text-decoration: none;
                color:white;
            }
            position: relative;
            left: -1.2rem;
            list-style: none;

            margin-top: 0.8rem;
        }

        img {
            width: 3rem;
        }
    }
 



}